export const ProjectList = [
  {
    title: 'Programing Language  & Interpreter',
    description:
      'Created a custom interpreted language called Lokus written in C++. Implemented lexical analysis and parsing using Flex and Bison. Designed and implemented various language features including variables, functions, loops, and control structures.',
    tech: 'C++, Flex, Bison',
    id: 0,
    page: [
      `This was an independent project I decided to take on as I thought that creating
      a language was an interesting and challenging goal. It was the largest
      project I have independently created at this point and it gave me lots of room to learn.`,
      `Previous to this project I had some exposure to the theory of programming languages
      through a course at URI so I had some grounding on the topic. First I started learning and reading
      as much as I could about language development to try to some footing on it. That lead me to
      completeing tutorials on youtube of small toy languages that got me familiar with the basics.
      With that knowledge under my belt I tried to create my new language, which I was calling Lokus.`,
      `My first goal was to write my own parser and lexer for the language as well as the interpreter.
      So I set off in C++ trying to create that with the little background I had from the youtube tutorials and preliminary research.
      It did not go well at all. After working on that for about 3 days I decided to shut it down. I realized
      that I had not adaquetly planned and thought out my design for the language and decisions made earlier 
      for convience were beginning to bite me. I also realized that writing a parser and a lexer was quite
      difficult and that I could use Flex and Bison to help and still leave me with more than enough work.`,
      `So after doing more research, learing how to write code in Flex and Bison, and planning out my design
      with the lessons learned previous I began again. This time through progress was a lot more steady and I
      felt that my goal was attainable. The most consequential change this time was using the Visitor design
      pattern for interpreting the AST tree Bison created. This architecture was a lot easier to work with and
      allowed me to get quick results printed to the console to make debugging a lot easier and give me hope.`,
      `Through development I also learned a lot about C++ and made plenty mistakes along the way. Eventually I learned
      the importance of the RAII principle as memory management began to become an issue. Like for example I only used
      raw pointers when I should have been creating smart pointers. I also used a lot of void pointers in the project
      which made development and testing a bit more tricky, and in hindsight I should have probably figured out a better
      typing system for the project. I learned a lot more little things here and there as well and overall thought the
      project was very beneficial to my development as C++ software engineer.`,
      `After I was significantly far into the project I realized that I hadn't formally tested anything. I was pretty sure everything
      was working as I was verifying that it looked like it was working in the console but never formally unit tested it.
      I realized that before I go any further I should probably bunker down and unit test for a while to ensure everything was
      operating as intended. So I learned the gtest framework and started to use it in my project. Luckily there were not too many
      bugs in my project so far and after creating the unit tests I was able to go back to development.`,
      `I decided that I would call my project completed when I was able to create a sudoku solver in my language.
      I knew that I could work on this project indefinitely so having that as a goal gave me a solid endpoint. So
      I continued working on the project, ironing out bugs, and eventually was able to create my sudoku solver. I imagine that
      there are probably still a lot of uncaught bugs in the language as of now but I am very proud of it. It took about 4 full weeks
      of concentrated effort from conception to finishing the sudoku solver and I am very happy to be done. :)`,
    ],
    image: '',
    caption: '',
    externalLink: 'https://github.com/AlfredT15/Lokus',
    externalText: 'Github',
  },
  {
    title: 'Multivariate Time Series Forecasting',
    description:
      'Conducted research on multivariate time-series forecasting on stock market data for a URI graduate-level Neural Network & Deep Learning class. Augmented a transformer model called the Spacetimeformer with a Sharpness Aware Minimizer and analyzed its performance on stock market data using Weights & Biases MLOps tool. Presented findings and wrote a 12-page paperfor a Deep Learning Workshop sponsored by TensorFlow and Google AI',
    tech: 'Python, Pytorch, MLOps',
    id: 1,
    page: [
      `This project was created for a URI graduate level Neural Network and Deep Learning class. We were tasked
      with creating a project that interested us using one of the Neural Network architecture we learned about.
      I decided to use a transformer as they seemed to be the most dominate arhcitecture at the time and would
      have good applications to time series data. I was already planning on doing something with stock market data
      as I have always been interested in the intersection between tech and finance. So I started this project by reading
      lots of papers about transformers and time series predicitons. I then found a paper by Jake Grigsby on the 
      SpaceTimeFormer, a new variant of the transformer that he had created.`,
      `This architecture flattend the spatio and teporal aspect of time series data into one long stream of variables.
      This allows the transformer to better learn the distinct temporal and spatial relationships between the variables.
      I also augmented the model with a Sharpness Aware Minimizer Optimizer as prior work has shown it's applicability with
      ResNets without the need for extensice pre-training. The SAM optimizer was desirable for my research as I only had limited access 
      to GPU's (and no TPU's) and so could not pretrain the transformer to leverage the architecture effectively.`,
      `I used historical stock data from yfinance to train my model. I also used the MLOps tool Weights and Biases to log my 
      results. In the end I did not get good results for predictions. As can be seen in the poster below the predicitons all
      have a large shift in their predicitons, but noteably the shape of the prediction is pretty good. Months after the project
      was completed that I found an issue discussion on the original authors github repo talking about the same issue I was facing.
      He had recommended using an additional linear model so that the model could also predict where to start. You can also read
      the report I wrote on the project on the github page below.`,
    ],
    image: '/images/CSC561_poster.png',
    caption:
      'This poster was presented at a Deep Learning Workshop sponsored by TensorFlow and Google AI',
    externalLink: 'https://github.com/AlfredT15/StockMarketPrediction',
    externalText: 'Github',
  },
  {
    title: 'CompilerGym for Compiler Optimizations',
    description:
      ' CompilerGym is a Deep Reinforcement Learning library created by Facebook Research to allow researchers to interact with compiler optimization problems. Worked on a team of 3 from a URI Machine Learning class to research and create a Deep Q-Network that scored 1.005x overthe -Oz macro, narrowly outperforming the expert-made compiler flag. Presented results and wrote a 15-page paper on our analysis, the project was recognized as ”outstanding” by the professor(automatically given an A in the course)',
    tech: 'Python, CompilerGym, Pytorch, MLOps',
    id: 2,
    page: [
      `
        This was a project for my CSC 461 and CSC 415 class. We had to use the CompilerGym library to 
        create a model that can be used to perform phase ordering for compiler optimization.`,
      `Phase ordering is a compiler optimization process in which the compiler chooses which order
        to apply the optimization flags to the code. Each optimization makes changes to the code which 
        could mean the following:\n(1) that previous optimizations that couldn't be applied before can now potentialy be applied \n(2) an
        optimization can be applied again \n(3) an optimization was effectively overwritten and is no longer
        relevant. This makes the phase ordering problem NP-Hard as the search space is infinite. One single
        optimization can technically be applied to the code infinitely. It is up to 
        the compiler to know how to apply optimizations to the code, in what order, and when to stop, so as
        to speed up the compilation process and make the code run as efficiently as possible.`,
      `Previously 'Expert made Heuristics' were used to solve this problem. But now Machine Learning is being 
        levaged to hopefully help get closer to a solution for this NP-Hard problem. That is where our work 
        with Compiler Gym came in, trying to frame this as a Deep Reinforcement Learning problem.`,
      `Through the use of Compiler Gym and other technologies such as Optuna, we created a model that was
        trying to optimize for code size reduction. This metric is the current metric that the Compiler Gym/Facebook/Meta 
        team are optimizing for and what they have their tools currently made for. Our team of 3 people were able
        to make it onto CompilerGym's top 10 model leaderboard at 9th place and created a model that performed 1.005% times
        better than the expert made -Oz compiler flag.`,
      `Through the process of this project we also created a presentation on Deep Reinforcement Learning
        and became very familiar with the topic. We explored and learned about lots of the different algorithms
        used in Deep Reinforcement Learning and got a good understanding of the major and most popular algorithms
        like DQN and PPO.`,
    ],
    image: '',
    caption: '',
    externalLink: 'https://github.com/raymondturrisi/csc461_project',
    externalText: 'Github',
  },
  // {
  //   title: 'Stock Market Social Media Aggregation',
  //   description: 'WIP',
  //   tech: '',
  //   id: 3,
  //   page: [''],
  //   image: '',
  //   caption: '',
  //   externalLink: '',
  //   externalText: '',
  // },
];

export const Experience = [
  {
    title: 'Software Engineer Intern',
    time: 'June - August 2022',
    employer: 'General Dynamics Mission System',
    description:
      'Worked on truMLS Drive, a web app for a secure file storage system. Implemented a feature that allowed folders to be moved between the file pane and folder tree. Was lead developer in the implementation of the Deep Content Inspection feature. Utilized agile development methodologies to efficiently build and iterate on the project. Presented my work in a live demo to customers and leadership',
    link: false,
    id: 1,
    page: [
      `I would segment my internship into three distinct sections. The first 
      period was when I was just getting started and acclimated to the environment 
      and work, the second period was when I began to do real work, and the final 
      period was when I felt confident and took on more responsibility.  I started 
      to learn tools like Jira, Confluence, and GitLab. At the end of the first week 
      and into the second week I was getting my development environment all set up 
      and starting to get familiar with the project. I learned tools like VMware and
      learned more about web development in a CI/CD workflow. This is the week where
      I first started touching the code and worked through a couple of bugs with one
      of the team members. The next week was when I got my first task
      of updating button styling on the entire application which was nice as I got to
      touch almost all aspects of the web app and see what kind of features it had in it.`,
      `Next I was responsible for testing a new feature. The feature was to make folders 
      visible and interactive in the right-hand pane. In my testing, 
      I realized that you were not able to drag folders and files around the screen 
      and drop them into other folders in the pane or on the folder tree, as is common in windows file viewers and other 
      applications. However this ability was not originally listed in the feature specifications.
      So I mentioned this idea to the team and they encouraged me to work 
      on implementing it. This was a large departure from my work so far as now I was 
      working on a real improvement to the product that required quite a bit of coding 
      and learning. It was through this feature that I was really able to understand and 
      feel very comfortable working with JQuery.`,
      `My next task was much more significant and it was to figure out how to use Clearswift 
      (A web gateway we were using for a Deep Content Inspection tool) and create a proof of concept for the implementation.
      Clearswift is a web gateway, which basically means all traffic that your machine sends 
      out to the web has to go through that gateway.  Prior to 
      this internship, I knew nothing about networking or web gateways, so while I was 
      figuring out how to set up a web gateway I also had to spend time learning all 
      the fundamentals of networking. It was a great experience and I learned a lot 
      through it. We ended up having to create a sub-net within the network to properly 
      use the Clearswift gateway in development. I then finished the implementation
      of the Deep Content Inspection system into our app and created popups for failed
      uploads and downloads.`,
    ],
    caption: '',
    image: '',
    externalLink: '',
    externalText: '',
  },
  {
    title: 'Arts & Sciences Fellow',
    time: 'May - September 2021',
    employer: 'University of Rhode Island',
    description:
      'Worked with Professor Marco Alvarez to create a tool to generate Compiler-based Graphs for Source Code Analysis. The tool helps researchers and engineers to significantly save time in their experimentation by providing multiple options for graph analysis, currently used by URI researchers.',
    link: false,
    id: 1,
    page: [
      `This project began at the conclusion of my Sophomore year and after a
    semester of getting up to speed on the scientific method in Computer
    Science. I was tasked with creating a library to speed up the process of
    discovery in Graph Neural Network compiler optimization research. To do
    this I was to create a library that could take a C program as input,
    compile with Clang, and then output graphs that were ready to be fed
    into a Graph Neural Network.`,
      `My library is able to output 3 types of graphs, Control Flow Graphs
    (CFG), Call Graphs, and a hybrid Control Flow plus Call Graph. All of
    these graphs were outtputted in NetworkX format (A common graph format
    for Neural Network Input) and in a pickle file for storage. A PNG can
    also be outputted for visual inspection of the graphs. These graphs can
    also have each node labeled with a feature vector so to be descriptive
    to the Neural Network. The nodes can be labeled with code2vec embedings,
    Autophase vectors, spaCy vectors, and LLVM instruction count vectors.`,
      `The library is currently an internal tool but has plans to be publish as
    a public Github Repo for the public use.`,
    ],
    caption:
      'This poster was presented at the URI undergraduate research showcase.',
    image: '/images/Poster.png',
    externalLink: 'https://web.uri.edu/artsci/fellows/alfred-timperley-22/',
    externalText: 'Arts and Science Fellow',
  },
  {
    title: 'Research Assistant',
    time: 'January 2021 - May 2022',
    employer: 'University of Rhode Island',
    description:
      'Assisted Professor Marco Alvarez and his research group in their work into Neural Networks and Compiler Optimization.',
    link: false,
    id: 2,
    page: [
      `I started as a research assistant in the begining of the second semester 
      sophomore year. During that semester I was invited to audit two graduate level courses 
      (561 Neural Networks and Deep Learning & 511 Advanced Computer Organization) for the purpose 
      of getting up to speed quicker. I also helped with simple tasks like writing python scripts
      to automate tasks and debugging code. During this semester I was learning as much as I could
      about Neural Networks and learning how scientific work was done in computer science.`,
      `Moving into the first semester of my Junior year I continued to work in this group. I would mainly
      create tools and explore leads about different technologies we were using. I also continued to refine the library
      I made over my summer fellowship. The big project I did for the research group that semester came in the form of 
      my final project in CSC 461 (Machine Learning) and CSC 415 (Intro to Parallel Computing). For that project 
      my group and I used Deep Reinforcement Learning to help with the phase ordering problem in compiler optimization. 
      To do this we used a new library developed by Facebook/Meta called CompilerGym which allowed us to approach
      compiler optimization as a reinforcement learning problem. We were able to create a model that got onto the top 10
      leaderboard for that library. The next semester I began to wind down my research efforts and pass off my
      projects to other researchers. I wanted to explore other aspects of computer science and math and needed to 
      free up more time in my schedule to help do that.`,
    ],
    caption: '',
    image: '',
    externalLink: '',
    externalText: '',
  },
  {
    title: 'Business Development Manager',
    time: 'August 2020 - January 2021',
    employer: 'Ambi Inc.',
    description:
      'Worked at a Social Media startup where I managed and launched a team of 20 brand ambassadors for their National College Ambassador program. Worked directly with the COO for hiring, management, compensation, and strategy.',
    link: false,
    id: 3,
    page: [
      `Ambi is a education social media startup that I worked for starting the summer before my sophomore year.
          I was hired because they were looking to start a National college ambassador program to bring their product 
          to market. In the beginning I worked directly with the COO of the company in creating the overall strategy
          for the initiative. I then wrote and developed the training protocol for how to onboard ambassadors and get
          them to start promoting Ambi at their schools.`,
      `Then we started the process of hiring ambassadors and used
          platforms such as Handshake, Pangea, HireUp, and others to reach potential ambassadors. I was in charge of 
          finding ambassadors, interviewing them, setting the pay structure (with approval), hiring them, onboarding them,
          and then managing them throughout the semester. At the end of the semester we had a team of 20 ambassadors throughout
          the country. I also lead an operations and strategy team within the company. We were a team of 3 college students
          who were in charge of generating leads and strategy for an influencer approach at promoting Ambi.`,
      `I stopped working for the company by the start of my second semester sophomore year due to issues with communication and
        payroll from leadership. Last I heard Ambi had been trying to pivot into a new business.`,
    ],
    caption: '',
    image: '',
    externalLink: 'http://ambi.network/',
    externalText: 'Ambi',
  },
  {
    title: 'Embedded Systems Engineer Intern',
    time: 'Summer 2019',
    employer: 'Schneider Electric',
    description:
      'Completed a functional analysis of the Deadtime Compensator Block (DTIME Block). Authored and presented an Engineering Document on this subject to the R&D team.',
    link: false,
    id: 4,
    page: [
      `I worked at Schneider Electric in the summer before my Freshman year of college. Here I conducted a Technical
            Analysis of one of their programing blocks. They were converting their platform over to a new technology and
            needed better documentation on how exactly their programming blocks operated so they could port it properly.`,
      `So I conducted research on the Deadtime Compensator (DTIME) Block and read all of the available documentation on how
          it operated. I also performed experiments on production software and read the written code to learn how the block
          functioned. With this knowledge I then began writing a Engineering Document specifying exactly how the DTIME block
          worked. I also authored a Testing Document showing what tests should be applied to the block to ensure all functionality
          is still available.`,
      `I then assisted in the porting of the block to the new technology, nxtSTUDIO IDE, and proposed a way to leverage the new IDE
        efficiently. I created a presentation on this topic and presented it to the R&D team. I am told that the documents I produced
        that summer are being used as the standard for future documentation of the program blocks.`,
    ],
    caption: '',
    image: '',
    externalLink: 'https://www.se.com/us/en/',
    externalText: 'Schneider Electric',
  },
];
