import React from 'react';
import { Route, Routes } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './GlobalStyles';
import Courses from './pages/Courses/Courses';
import Error from './pages/Error/Error';
import Home from './pages/Home/Home';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import DetailedExperience from './pages/DetailedExperience/DetailedExperience';

const theme = {
  colors: {
    primary: '#001820',
    secondary: '#99e7ff',
    secondary2: '#80e0ff',
    background1: '#001B2E',
    white: '#CEDFD9',
    text: '#000000',
  },
  fonts: {
    title: ['Didot', 'sans-serif'],
    main: ['Didot', 'sans-serif'],
  },
  breakpoints: {
    xxs: 'screen and (max-width: 320px)',
    xs: 'screen and (max-width: 425px)',
    sm: 'screen and (max-width: 640px)',
    md: 'screen and (max-width: 768px)',
    lg: 'screen and (max-width: 1024px)',
    xl: 'screen and (max-width: 1280px)',
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/courses/:id" element={<Courses />} />
        <Route exact path="/experience/:id" element={<DetailedExperience />} />
        <Route exact path="/projects/:id" element={<DetailedExperience />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
