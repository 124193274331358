import React from 'react';
import { Link } from 'react-router-dom';
import {
  LinkPreview,
  Photo,
  Section,
  SectionDivider,
  SectionSpan,
  SectionText,
  SectionTitle,
  VertDivider,
} from '../../GlobalStyles';
import { EdSection, EdSpan } from '../Academics/Academics.styled';
import { CoursesList } from '../Academics/CoursesConstants';
import { Box, BoxText } from './Box.styled';
import {
  Headshot,
  HomeSection,
  PortfolioTitle,
  XPSection,
} from './Home.styled';
import { Experience, ProjectList } from './HomeConstants';

function Home() {
  return (
    <Section center>
      <Section flex row>
        <Headshot src="images/Headshot.png" alt="snow" />
        <Section>
          <SectionTitle center>Welcome!</SectionTitle>
          <SectionText indent>
            My name is Alfred, and I am a Senior at the University of Rhode
            Island set to graduate in May 2023. I am currently pursuing dual
            majors in Computer Science and Applied Mathematics, with a minor in
            Economics and Data Science. My interests are diverse and include
            Artificial Intelligence, Information Theory, Chaos Theory, Finance,
            and Neuroscience.
          </SectionText>
          <SectionText>
            I am a hands-on learner who loves to apply my knowledge to the real
            world and build things. I am highly passionate and driven, and have
            a proven track record of leadership and initiative.
          </SectionText>
        </Section>
      </Section>
      <SectionDivider long center />
      <PortfolioTitle main>Projects</PortfolioTitle>
      <SectionDivider long bottomMargin />
      <SectionText nopadding>Select a project to see more</SectionText>
      <HomeSection flex row fwrap nopadding center>
        {ProjectList.map(project => (
          <Link to={`/projects/${project.id}`} state={project}>
            <Box key={project.id}>
              <BoxText margin bold>
                {project.title}
              </BoxText>
              <BoxText small>
                {' '}
                <EdSpan bold>{project.tech ? 'Tech:' : ''}</EdSpan>{' '}
                {project.tech}
              </BoxText>
              <BoxText small grey>
                {project.description.split(' ').slice(0, 35).join(' ')}
                {project.description.split(' ').length > 35 ? '...' : ''}
              </BoxText>
            </Box>
          </Link>
        ))}
      </HomeSection>

      <PortfolioTitle main>Experience</PortfolioTitle>
      <SectionDivider long bottomMargin />
      <SectionText nopadding>Select an experience to see more</SectionText>
      <HomeSection flex row fwrap nopadding center>
        {Experience.map(xp => {
          return (
            <Link to={`/experience/${xp.id}`} state={xp}>
              <Box>
                <BoxText bold>{xp.title}</BoxText>
                <XPSection row>
                  <BoxText nopadding small italic grow>
                    {xp.time}
                  </BoxText>
                  <BoxText nopadding small italic>
                    {xp.employer}
                  </BoxText>
                </XPSection>
                <BoxText nopadding center grey small>
                  {xp.description.split(' ').slice(0, 50).join(' ')}
                  {xp.description.split(' ').length > 50 ? '...' : ''}
                </BoxText>
              </Box>
            </Link>
          );
        })}
      </HomeSection>

      <SectionTitle main>Publications</SectionTitle>
      <SectionDivider long />

      <HomeSection flex row fwrap nopadding center>
        <LinkPreview url="https://medium.com/@atimperley15/on-intelligence-7ec08f600b26"></LinkPreview>
      </HomeSection>

      <SectionTitle main>Education</SectionTitle>
      <SectionDivider long />
      <Section row>
        <Photo logo src="images/URI_Logo.png" alt="URI logo" />
        {/* <VertDivider divider></VertDivider> */}
        <EdSection col nopadding wrap center>
          <SectionText nopadding bold>
            University of Rhode Island
          </SectionText>
          <SectionText nopadding>
            <SectionSpan bold>Cum. GPA:</SectionSpan> 3.81 / 4.0
          </SectionText>
          <SectionText>
            <SectionSpan bold>CS GPA:</SectionSpan> 3.91 / 4.0
          </SectionText>
        </EdSection>
        <VertDivider divider></VertDivider>
        <EdSection col nopadding wrap center>
          <SectionText nopadding>
            <SectionSpan bold>Majors:</SectionSpan> Computer Science BS
          </SectionText>
          <SectionText indent nopadding>
            Applied Mathematics BS
          </SectionText>
          <SectionText nopadding>
            <SectionSpan bold>Minors:</SectionSpan> Economics
          </SectionText>
          <SectionText indent nopadding>
            Data Science
          </SectionText>
        </EdSection>
      </Section>
      <br />
      <SectionTitle>Courses</SectionTitle>
      <SectionDivider long />
      <br />
      <SectionText>Relevant courses I have taken.</SectionText>
      <EdSection flex row wrap nopadding center>
        {CoursesList.map(course => (
          <Box auto noHover>
            <BoxText>
              <EdSpan bold>{course.code}:</EdSpan> {course.name}
            </BoxText>
            <BoxText small>
              <EdSpan italic>
                Grade: {course.grade}, Taken: {course.taken}
              </EdSpan>
            </BoxText>

            <BoxText small grey>
              {/* {course.description.split(' ').slice(0, 30).join(' ')}
              {course.description.split(' ').length > 30 ? '...' : ''} */}
              {course.description}
            </BoxText>
            <BoxText small bold>
              {course.comments}
            </BoxText>
          </Box>
        ))}
      </EdSection>
    </Section>
  );
}

export default Home;
