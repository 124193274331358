import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  Graphic,
  Section,
  SectionDivider,
  SectionText,
  SectionTitle,
} from '../../GlobalStyles';
import { Anchor, ExternalLink } from './DetailedExperience.styled';

function DetailedExperience() {
  const location = useLocation();
  const { title, page, image, caption, externalLink, externalText } =
    location.state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionDivider long divider />
      {page.map(paragraph => (
        <SectionText indent center>
          {paragraph}
        </SectionText>
      ))}
      <>
        {image === '' ? (
          <br />
        ) : (
          <>
            <SectionDivider divider />
            <SectionTitle>Graphic</SectionTitle>
            <SectionText>{caption}</SectionText>
            <Graphic src={image} alt={caption} />
            <SectionDivider divider />
          </>
        )}
      </>
      <>
        {externalLink === '' ? (
          <br />
        ) : (
          <>
            <Anchor href={externalLink}>
              <ExternalLink>{externalText}</ExternalLink>
            </Anchor>
          </>
        )}
      </>
    </Section>
  );
}

export default DetailedExperience;
