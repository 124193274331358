import styled from 'styled-components';
import { EdText } from '../Academics/Academics.styled';
//#00222e #242526 #242532 #0B0C19
export const Box = styled.div`
  height: ${props => (props.auto ? 'auto' : '20rem')};
  width: 45rem;
  background-color: ${props =>
    props.grey ? '#252633' : props.theme.colors.primary};
  box-shadow: 0.35rem 0.35rem 0.35rem black;
  border-top: 1rem solid ${props => props.theme.colors.secondary2};
  border-left: 1rem solid ${props => props.theme.colors.secondary2};
  margin-bottom: 3rem;
  margin-right: 3rem;
  align-content: center;
  overflow: hidden;

  &:hover {
    transform: ${props => (props.noHover ? '' : 'scale(1.005)')};
  }

  @media ${props => props.theme.breakpoints.md} {
    min-width: 20rem;
    min-height: 10rem;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
  }

  @media ${props => props.theme.breakpoints.sm} {
    min-width: 20rem;
    min-height: 10rem;
    width: auto;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }
`;

export const BoxText = styled(EdText)`
  margin-left: 2rem;
  margin-top: ${props => (props.margin ? '2rem' : '0')};
  padding-bottom: ${props => (props.small ? '1rem' : '0')};
  font-size: ${props => (props.small ? '18px' : '20px')};
  line-height: ${props => (props.small ? '20px' : '30px')};
  color: ${props => (props.grey ? 'grey' : 'white')};

  @media ${props => props.theme.breakpoints.md} {
    font-size: 18px;
    line-height: 32px;
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 24px;
  }
`;
