import React from 'react';
import { VertDivider } from '../../GlobalStyles';
import { FooterGroup, FooterItem, FooterMenu } from './Footer.styled';

function Footer() {
  return (
    <FooterGroup>
      <FooterMenu>
        <FooterItem>401-871-2635</FooterItem>
        <VertDivider small keep />
        <FooterItem>atimperley15@gmail.com</FooterItem>
      </FooterMenu>
    </FooterGroup>
  );
}

export default Footer;
