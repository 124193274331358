import styled from 'styled-components';
import { Section, SectionText } from '../../GlobalStyles';

export const EdText = styled(SectionText)`
  padding-right: ${props => (props.padding ? '2rem' : '0')};
  justify-self: start;
  flex-grow: ${props => (props.grow ? '1' : '0')};
  color: ${props => (props.color ? 'black' : 'white')};
`;

export const EdSection = styled(Section)`
  // flex-grow: 2;
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  grid-template-columns: ${props =>
    props.double ? '1fr 3fr 1fr 3fr' : '1fr 3fr'};
  row-gap: 0;
  justify-content: ${props => (props.center ? 'center' : 'normal')};
  align-content: ${props => (props.center ? 'center' : 'normal')};

  @media ${props => props.theme.breakpoints.sm} {
    justify-content: center;
    align-self: center;
    align-content: center;
  }
`;

export const EdSpan = styled.span`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  padding-right: ${props => (props.padding ? '2rem' : '0')};
  padding-left: ${props => (props.padding ? '2rem' : '0')};
  margin-left: ${props => (props.indent ? '9rem' : '0')};
  @media ${props => props.theme.breakpoints.sm} {
    margin-left: ${props => (props.indent ? '6rem' : '0')};
  }
`;
