import React from 'react';
import { useLocation } from 'react-router';
import {
  Section,
  SectionDivider,
  SectionText,
  SectionTitle,
} from '../../GlobalStyles';

function Courses() {
  const location = useLocation();
  const { code, name, description } = location.state;

  return (
    <Section>
      <SectionTitle main>
        {code}: {name}
      </SectionTitle>
      <SectionDivider long />
      <Section flex row>
        <SectionText>{description}</SectionText>
      </Section>
    </Section>
  );
}

export default Courses;
