import styled from 'styled-components';
import { ButtonBack } from '../../GlobalStyles';

export const Anchor = styled.a`
  color: ${props => props.theme.colors.secondary2};
  &:hover {
    transform: scale(1.005);
    cursor: pointer;
  }
`;

export const Graphic = styled.img``;

export const ExternalLink = styled(ButtonBack)`
  background: ${props => props.theme.colors.secondary2};
  align-self: center;
  margin: auto;
  color: black;
`;
