import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Microlink from '@microlink/react';

export const GlobalStyles = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: scroll; 

  }
  body {
    font-family: ${props => props.theme.fonts.main};
    font-size: 1.6rem;
    background: ${props => props.theme.colors.background1};
    color: white;
    cursor: default;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;

  }
  body:before {
    content: "";
    height: 100%;
    float: left;
    width: 0;
    margin-top: -32767px;
    /* thank you Erik J - negate effect of float*/
  }
  h1,h2,h3,h4,h5,h6,button {
    font-family: ${props => props.theme.fonts.title};
  }
  a {
    text-decoration: none;
  }
  li{
    list-style: none;
  }
`;

export const LinkText = styled(Link)`
  color: ${props => props.theme.colors.secondary2};
`;

export const Graphic = styled.img`
  height: auto;
  width: auto;
`;

export const Photo = styled.img`
  height: ${props => (props.logo ? '200px' : '500px')};
  width: ${props => (props.logo ? '200px' : '400px')};
  @media ${props => props.theme.breakpoints.sm} {
    height: 250px;
    width: 250px;
  }

  @media ${props => props.theme.breakpoints.xs} {
    height: 200px;
    width: 200px;
  }
`;

export const Section = styled.section`
  display: ${props => (props.grid ? 'grid' : 'flex')};
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  padding: ${props => (props.nopadding ? '0' : '32px 48px 0')};
  padding-top: ${props => (props.noTopPadding ? '0' : '32px')};
  margin: ${props => (props.nomargin ? '0' : '0 auto')};
  max-width: 1140px;
  width: auto;
  height: auto;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
  // grid-template-columns: 1fr 1fr;
  grid-template-columns: ${props =>
    props.double ? '1fr 3fr 1fr 3fr' : '1fr 1fr'};
  // justify-content: center;
  // align-content: center;
  // align-self: center;

  @media ${props => props.theme.breakpoints.md} {
    padding: ${props => (props.nopadding ? '0' : '24px 48px 0')};
    flex-direction: column;
  }

  @media ${props => props.theme.breakpoints.sm} {
    padding: ${props => (props.nopadding ? '0' : '16px 16px 0')};

    // width: calc(100vw - 32px);
    width: 100%
    flex-direction: column;

    // justify-content: center;
    // align-content: center;
    // align-self: center;
  }
`;

export const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${props => (props.main ? '75px' : '66px')};
  line-height: ${props => (props.main ? '82px' : '72px')};
  width: max-content;
  max-width: 100%;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  align-self: ${props => (props.center ? 'center' : 'normal')};
  padding: ${props => (props.main ? '58px 0 16px' : '0')};

  @media ${props => props.theme.breakpoints.md} {
    font-size: ${props => (props.main ? '56px' : '48px')};
    line-height: ${props => (props.main ? '56px' : '48px')};
    margin-bottom: 12px;
    padding: ${props => (props.main ? '40px 0 12px' : '0')};
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 32px;
    line-height: 40px;
    font-size: ${props => (props.main ? '28px' : '32px')};
    line-height: ${props => (props.main ? '32px' : '40px')};
    margin-bottom: 8px;
    padding: ${props => (props.main ? '16px 0 8px' : '0')};
    max-width: 100%;
  }
`;

export const SectionText = styled.p`
  text-indent: ${props => (props.indent ? '9rem' : '0')};
  max-width: 1000px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 300;
  padding-bottom: ${props => (props.nopadding ? '0' : '3.6rem')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  color: white;
  align-self: ${props => (props.center ? 'center' : 'normal')};

  @media ${props => props.theme.breakpoints.md} {
    max-width: 670px;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 24px;
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
  }
`;

export const SectionSpan = styled.span`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
`;

export const SectionDivider = styled.div`
  width: ${props => (props.long ? '192px' : '64px')};
  height: 6px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: ${props => (props.bottomMargin ? '5px' : '0')};
  background: ${props => props.theme.colors.secondary2};
  margin: ${props => (props.divider ? '4rem 0' : '')};
  align-self: ${props => (props.center ? 'center' : 'normal')};

  @media ${props => props.theme.breakpoints.md} {
    width: 48px;
    height: 4px;
  }

  @media ${props => props.theme.breakpoints.sm} {
    width: 32px;
    height: 2px;
  }
`;

export const VertDivider = styled.div`
  width: 4px;
  height: ${props => (props.small ? '48px' : '64px')};
  border-radius: 10px;
  background-color: #fff;
  align-self: center;
  background: ${props => props.theme.colors.secondary2};
  margin: ${props => (props.divider ? '1rem 1.5rem' : '')};

  @media ${props => props.theme.breakpoints.md} {
    display: ${props => (props.keep ? 'normal' : 'none')};
  }

  @media ${props => props.theme.breakpoints.sm} {
    display: ${props => (props.keep ? 'normal' : 'none')};
  }
`;

export const SectionSubText = styled.p`
  max-width: 800px;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.75);

  @media ${props => props.theme.breakpoints.md} {
    max-width: 672px;
    font-size: 16px;
    line-height: 25px;
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 22px;
  }
`;
export const SecondaryBtn = styled.button`
  color: #fff;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.33);
  box-sizing: border-box;
  border-radius: 999px;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  width: fit-content;
  margin-top: 32px;
  margin-bottom: 80px;
  cursor: pointer;
  transition: 0.4s ease;
  &:focus {
    outline: none;
  }

  &:hover {
    color: #0f1624;
    background: #fff;
    border: 1px solid #fff;
  }

  &:active {
    background: #e0e4eb;
    border: 1px solid #304169;
    box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15),
      inset 0px 0px 4px rgba(20, 20, 55, 0.3);
  }

  @media ${props => props.theme.breakpoints.md} {
    margin-top: 24px;
    margin-bottom: 64px;
    padding: 16px 24px;
    width: fit-content;
    font-size: 20px;
    line-height: 20px;
  }

  @media ${props => props.theme.breakpoints.sm} {
    margin-top: 16px;
    margin-bottom: 40px;
    padding: 8px 16px;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const ButtonBack = styled.div`
  width: ${({ alt }) => (alt ? '150px' : '262px')};
  height: ${({ alt }) => (alt ? '52px' : '64px')};
  border-radius: 50px;
  font-size: ${({ alt }) => (alt ? '20px' : '24px')};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ alt, form }) => (alt || form ? '0' : '0 0 80px')};
  color: #fff;
  background: ${({ alt }) =>
    alt
      ? 'linear-gradient(270deg, #ff622e 0%, #B133FF 100%)'
      : 'linear-gradient(270deg, #00DBD8 0%, #B133FF 100%)'};
  cursor: pointer;
  transition: 0.5s ease;
  position: relative;
  overflow: hidden;
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};

  @media ${props => props.theme.breakpoints.md} {
    width: ${({ alt }) => (alt ? '150px' : '184px')};
    height: ${({ alt }) => (alt ? '52px' : '48px')};
    font-size: ${({ alt }) => (alt ? '20px' : '16px')};
    margin-bottom: ${({ alt }) => (alt ? '0' : '64px')};
  }

  @media ${props => props.theme.breakpoints.sm} {
    width: 100%;
    height: 32px;
    font-size: 14px;
    margin-bottom: ${({ alt }) => (alt ? '0' : '32px')};
  }
`;

export const ButtonFront = styled.button`
  border: none;
  border-radius: 50px;
  color: #fff;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ alt }) =>
    alt
      ? 'linear-gradient(270deg, #F46737 0%, #945DD6 100%)'
      : 'linear-gradient(270deg, #13ADC7 0%, #945DD6 100%)'};
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
  transition: 0.4s ease;
  font-size: ${({ alt }) => (alt ? '20px' : '24px')};
  font-weight: 600;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: ${({ disabled }) =>
    disabled
      ? 'inset 0px 2px 1px rgba(46, 49, 55, 0.15), inset 0px 0px 4px rgba(20, 20, 55, 0.3)'
      : 'none'};

  &:hover {
    opacity: 0;
  }
  &:focus {
    outline: none;
  }
  &:active {
    opacity: 1;
    box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15),
      inset 0px 0px 4px rgba(20, 20, 55, 0.3);
  }

  &:disabled {
    background: linear-gradient(270deg, #00dbd8 0%, #b133ff 100%);
    opacity: 0.5;
    box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15),
      inset 0px 0px 4px rgba(20, 20, 55, 0.3);
  }

  @media ${props => props.theme.breakpoints.md} {
    font-size: ${({ alt }) => (alt ? '20px' : '16px')};
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 14px;
  }
`;

export const LinkContainer = styled.div`
  margin-left: ${({ large }) => (large ? '24px' : '16px')};
  transition: 0.3s ease;
  justify-content: center;
  border-radius: 50px;
  padding: 8px;

  &:hover {
    background-color: #212d45;
    transform: scale(1.2);
    cursor: pointer;
  }

  @media ${props => props.theme.breakpoints.md} {
    margin-left: ${({ large }) => (large ? '16px' : '8px')};
  }
  @media ${props => props.theme.breakpoints.sm} {
    margin-left: ${({ large }) => (large ? '0' : '8px')};
  }
`;

export const LinkIconImg = styled.div`
  display: flex;
  height: ${({ large }) => (large ? '32px' : '24px')};

  @media ${props => props.theme.breakpoints.md} {
    height: ${({ nav }) => (nav ? '16px' : '24px')};
  }

  @media ${props => props.theme.breakpoints.sm} {
    height: ${({ large }) => (large ? '32px' : '16px')};
  }
`;

export const LinkPreview = styled(Microlink)`
  color: white;
  background-color: ${props => props.theme.colors.primary};

  :hover {
    background-color: ${props => props.theme.colors.primary};
  }
  @media ${props => props.theme.breakpoints.sm} {
    width: 75%;
  }
`;
