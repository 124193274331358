export const CoursesList = [
  {
    name: 'Neural Networks & Deep Learning',
    code: 'CSC 561',
    credits: '4',
    description:
      'Survey of traditional and newly developed deep learning methods, including convolutional networks, recurrent networks, auto-encoders, and generative adversarial networks, as well as, their application to real-world problems.',
    grade: 'A',
    taken: 'Spring 2022',
    comments: 'Graduate course',
    id: 0,
  },
  {
    name: 'Matrix Analysis & Applications',
    code: 'MTH 518',
    credits: '3',
    description:
      'Topics in matrix analysis with applications - similarity and eigenvalues; Hermitian and normal matrices; canonical forms; norms; least square, eigenvalue localizations; singular value decomposition; definite matrices.',
    grade: '-',
    taken: 'Spring 2023',
    comments: 'Graduate course',
    id: 1,
  },
  {
    name: 'Machine Learning',
    code: 'CSC 461',
    credits: '4',
    description:
      'Broad introduction to fundamental concepts in machine learning. Survey of traditional and newly developed learning algorithms, as well as, their application to real-world problems.',
    grade: 'A',
    taken: 'Fall 2021',
    comments: 'Final project awarded "Outstanding"',
    id: 2,
    image: 'machineL.jpeg',
  },
  {
    name: 'Data Structures & Algorithms',
    code: 'CSC 212',
    credits: '4',
    description:
      'Abstract data types and data structures. Pointers, linked lists, stacks, queues, binary trees, and tables. Fundamentals of software engineering. Development of object-oriented programming techniques.',
    grade: 'A',
    taken: 'Fall 2020',
    comments: 'Final project awarded "Outstanding"',
    id: 3,
    image: 'machineL.jpeg',
  },
  {
    name: 'Introduction to Parallel Computing',
    code: 'CSC 415',
    credits: '4',
    description:
      'Programming techniques to engage a collection of autonomous processors to solve large-scale numerical and non-numerical problems. Processor interconnections. Parallel programming languages and models. Performance measures.',
    grade: 'A',
    taken: 'Fall 2021',
    comments: '',
    id: 4,
  },
  {
    name: 'Software Engineering',
    code: 'CSC 305',
    credits: '4',
    description:
      'Programming environments and methodologies for the design, development, testing, and maintenance of large software systems. Student teams will develop a substantial software product from requirements to delivery using disciplined techniques.',
    grade: 'A',
    taken: 'Summer 2021',
    comments: '',
    id: 5,
  },
  {
    name: 'Database Management Systems',
    code: 'CSC 436',
    credits: '4',
    description:
      'Construction and management of large data systems. Data modeling, relational and object-oriented systems, main memory databases, query languages, query optimization, concurrency control, transaction management, distributed systems, disk organization, indexes, and emerging technologies.',
    grade: 'A',
    taken: 'Fall 2022',
    comments: '',
    id: 6,
  },
  {
    name: 'Operating Systems',
    code: 'CSC 412',
    credits: '4',
    description:
      'General concepts underlying operating systems and computer networks. Topics include process management, concurrency, scheduling, memory management, information management, protection and security, modeling and performance, networking and communication.',
    grade: 'A',
    taken: 'Spring 2022',
    comments: '',
    id: 7,
  },
  {
    name: 'Computer Organization',
    code: 'CSC 411',
    credits: '4',
    description:
      'Logical structure of computer systems viewed as a hierarchy of levels. Assembly language programming, assemblers, linkers, loaders. Computer architecture including digital logic, processor organization, instruction sets, addressing techniques, virtual memory, microprogramming.',
    grade: 'A',
    taken: 'Spring 2021',
    comments: 'Was a Teaching Assistant for this course',
    id: 8,
  },
  {
    name: 'Advanced Calculus and Applications I & II',
    code: 'MTH 437 / 438',
    credits: '3 / 3',
    description:
      "Sequences, limits, continuity, differentiability, Riemann integrals, functions of several variables, multiple integrals, space curves, line integrals, surface integrals, Green's theorem, Stokes' theorem, series, improper integrals, uniform convergence, Fourier series, Laplace transforms. Applications to physics and engineering emphasized.",
    grade: 'A / -',
    taken: 'Fall 2022 / Spring 2023',
    comments: '',
    id: 9,
  },
  {
    name: 'Introduction to Multivariate Statistical Learning',
    code: 'DSP 441',
    credits: '4',
    description:
      'Multivariate data organization and visualization, multinomial and multivariate normal distribution, tests of hypotheses on mean vectors, multivariate regression and classification, principal component analysis, clustering, cross-validation and bootstrapping.',
    grade: '-',
    taken: 'Spring 2023',
    comments: '',
    id: 10,
  },
  {
    name: 'Engineering Entrepreneurship I',
    code: 'EGR 325',
    credits: '3',
    description:
      'Fundamentals of entrepreneurship for engineers including project planning and budgeting, elements of a business plan, financial acumen, presentation skills and manufacturing planning.',
    grade: 'A',
    taken: 'Fall 2019',
    comments: '',
    id: 11,
  },
  {
    name: 'Business of Innovation: Lean Startup',
    code: 'INE 249',
    credits: '3',
    description:
      'Applies the Lean Startup Scientific Method for developing and commercializing ideas for new ventures (entrepreneurship), and innovating new products, services or business models within existing companies (intrapreneurship).',
    grade: 'A',
    taken: 'Spring 2021',
    comments: '',
    id: 12,
  },
];
