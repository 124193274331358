// import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import styled from 'styled-components';

export const Nav = styled(Navbar)`
  background: ${props => props.theme.colors.primary};
  height: 60px;
  width: 100%;
  display: flex;
  z-index: 12;
  position: sticky;
  top: 0;
`;

export const NavLink = styled(Link)`
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-size: 2rem;
  cursor: pointer;
  &.active {
    color: ${props => props.theme.colors.secondary};
  }
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 1.8rem;
  }

  @media ${props => props.theme.breakpoints.xs} {
    font-size: 1.5rem;
    padding: 0 0.5rem;
  }
  @media ${props => props.theme.breakpoints.xxs} {
    font-size: 1.3rem;
    padding: 0 0.5rem;
  }
`;

// export const Bars = styled(FaBars)`
//   display: none;
//   color: #ffffff;
//   @media ${props => props.theme.breakpoints.md} {
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//     cursor: pointer;
//   }
//   .sideBar {
//     width: 10%;
//     display: flex;
//     margin-right: 0;
//     background-color: ${props => props.theme.colors.cyan};
//   }
// `;

export const Title = styled(Link)`
  color: #ffffff;
  font-size: 2.7rem;
  margin: auto;
  margin-left: 5%;
  cursor: pointer;
  text-align: center;

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 2.4rem;
  }

  @media ${props => props.theme.breakpoints.xs} {
    font-size: 1.5rem;
  }
  @media ${props => props.theme.breakpoints.xxs} {
    font-size: 1.3rem;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  margin: auto;
  text-align: center;
  padding: 25px;
  @media ${props => props.theme.breakpoints.sm} {
    padding: 22px;
  }

  @media ${props => props.theme.breakpoints.xs} {
    padding: 20px;
  }
  @media ${props => props.theme.breakpoints.xxs} {
    padding: 15px;
  }
`;

export const SocialMenu = styled.div`
  // display: flex;
  margin: auto;
  margin-right: 5%;
`;

export const SocialIcons = styled.a`
  transition: 0.3s ease;
  color: white;
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
`;
