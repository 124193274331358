import styled from 'styled-components';

export const FooterGroup = styled.div`
  background: ${props => props.theme.colors.primary};
  margin-top: 30px;
  position: relative:
  height: 60px;
  width: 100%;
  display: flex;
  z-index: 12;
  justify-content: center;
  align-items: center;
`;

export const FooterMenu = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: -55px;
  @media ${props => props.theme.breakpoints.sm} {
    right: -45px;
  }

  @media ${props => props.theme.breakpoints.xs} {
    right: -40px;
  }
  @media ${props => props.theme.breakpoints.xxs} {
    right: -35px;
  }
`;

export const FooterItem = styled.div`
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-size: 2rem;

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 1.8rem;
  }

  @media ${props => props.theme.breakpoints.xs} {
    font-size: 1.5rem;
    padding: 0 0.5rem;
  }
  @media ${props => props.theme.breakpoints.xxs} {
    font-size: 1.1rem;
    padding: 0 0.5rem;
  }
`;
