import styled from 'styled-components';
import { ButtonBack, Section, SectionTitle } from '../../GlobalStyles';

// export const LandingSection = styled(Section)`
//   padding-bottom: 100px;
// `;

export const ContactMeButton = styled(ButtonBack)`
  background: ${props => props.theme.colors.secondary2};
  align-self: center;
  margin-top: auto;

  @media ${props => props.theme.breakpoints.sm} {
    width: 80%;

  @media ${props => props.theme.breakpoints.xxs} {
    width: 80%;
  }
`;

export const HomeSection = styled(Section)`
  positon: ;
  margin: 0;
  flex-grow: 2;
  flex-wrap: ${props => (props.fwrap ? 'wrap' : 'nowrap')};
  grid-template-columns: ${props =>
    props.double ? '1fr 3fr 1fr 3fr' : '1fr 3fr'};
  row-gap: 0;
  justify-content: ${props => (props.center ? 'center' : 'normal')};
  align-content: ${props => (props.center ? 'center' : 'normal')};

  @media ${props => props.theme.breakpoints.sm} {
    justify-content: center;
    align-self: center;
    align-content: center;
  }
`;

export const Headshot = styled.img`
  height: 500px;
  width: 400px;
  z-index: 2;
  margin: 10px;
  align-self: center;
  box-shadow: 8px 8px ${props => props.theme.colors.secondary2};

  @media ${props => props.theme.breakpoints.md} {
    height: 400px;
    width: 320px;
  }

  @media ${props => props.theme.breakpoints.sm} {
    height: 300px;
    width: 240px;

  @media ${props => props.theme.breakpoints.xxs} {
    height: 200px;
    width: 160px;
  }
`;

export const PortfolioTitle = styled(SectionTitle)`
  z-index: 2;
`;

export const XPSection = styled.div`
  display: ${props => (props.grid ? 'grid' : 'flex')};
  flex-direction: ${props => (props.row ? 'row' : 'column')};
`;
