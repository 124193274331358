import React from 'react';
import { Nav, SocialIcons, SocialMenu, Title } from './NavBar.styled';
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';

function NavBar() {
  return (
    <Nav>
      <Title to="/">Alfred Timperley</Title>
      <SocialMenu>
        <SocialIcons href="https://github.com/AlfredT15">
          <AiFillGithub size="3rem" />
        </SocialIcons>
        <SocialIcons href="https://www.linkedin.com/in/alfred-timperley-b1734718b/">
          <AiFillLinkedin size="3rem" />
        </SocialIcons>
      </SocialMenu>
    </Nav>
  );
}

export default NavBar;
